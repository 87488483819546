import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'

import {
	ContentWrapper,
	HangingQuote,
	WistiaVideoOverlay,
} from 'views/components'
import BartonMalowLogo from 'imgs/logos/barton-malow.svg'
import PlayButtonSVG from 'icons/play-button.svg'
import { tablet, mobile } from 'shared/media-queries'

const ImageAndWording = styled.div`
	position: relative;
	max-width: 100%;
	flex: 0 0 auto;
`
const ImageWrapper = styled.div`
	${tablet} {
		img {
			object-position: 63% !important;
		}
	}
	${mobile} {
		height: 200px;
	}
`
const PositionContainer = styled.div`
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
`
const Wording = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	color: white;
	flex-direction: column;
`
const Quote = styled(HangingQuote)`
	max-width: 500px;
	font-size: 38px;
	margin-bottom: 50px;
	${mobile} {
		font-size: 17px;
		max-width: 200px;
		margin-bottom: 10px;
	}
`
const Signature = styled.div`
	padding-bottom: 20px;
	${mobile} {
		font-size: 12px;
		padding-bottom: 10px;
	}
`
const CompanyLogo = styled(BartonMalowLogo)`
	g {
		fill: white;
	}
	${mobile} {
		width: 54px;
	}
`
const WatchVideo = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
	color: white;
	height: 70px;
	display: flex;
	align-items: center;
	font-size: 32px;
	background-color: rgba(0, 0, 0, 0.5);
	font-weight: 500;
	padding: 15px;
	${mobile} {
		font-size: 14px;
		height: 35px;
		padding: 10px;
	}
`
const PlayButton = styled(PlayButtonSVG)`
	margin-right: 10px;
	${mobile} {
		height: 24px;
		width: 24px;
		margin-right: 5px;
	}
`

interface Props {
	quote: string
}

const LargeImage: React.FC<Props> = ({ quote }) => {
	const data = useStaticQuery(
		graphql`
			query {
				image: file(
					relativePath: { eq: "homepage/videos/large-image/image.jpg" }
				) {
					childImageSharp {
						fixed(height: 460) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<ImageAndWording>
			<ImageWrapper>
				<GatsbyImage
					fixed={data.image.childImageSharp.fixed}
					style={{ display: 'block', maxWidth: '100%', maxHeight: '100%' }}
				/>
			</ImageWrapper>
			<PositionContainer>
				<ContentWrapper>
					<Wording>
						<Quote>{quote}</Quote>
						<Signature>
							<b>Daryl Dombrow</b>, Senior Project Manager
						</Signature>
						<CompanyLogo />
					</Wording>
				</ContentWrapper>
			</PositionContainer>
			<WatchVideo>
				<WistiaVideoOverlay videoId="qcf8jcg372" />
				<PlayButton />
				Watch video
			</WatchVideo>
		</ImageAndWording>
	)
}

export default LargeImage
