import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const Image2 = () => {
	const data = useStaticQuery(
		graphql`
			query {
				image: file(
					relativePath: { eq: "homepage/solutions/images/ie/image2.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 4000) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	return <GatsbyImage fluid={data.image.childImageSharp.fluid} />
}

export default Image2
