import { countryCodes } from 'shared/get-country'

import { Image1, Image2, Image3 } from './us'
import { Image1 as anzImage1, Image2 as anzImage2 } from './anz'
import { Image1 as ukImage1, Image2 as ukImage2 } from './uk'
import { Image1 as ieImage1, Image2 as ieImage2 } from './ie'

const usImages = { Image1, Image2, Image3 }
const anzImages = { Image1: anzImage1, Image2: anzImage2 }
const ieImages = { Image1: ukImage1, Image2: ukImage2 }
const ukImages = { Image1: ieImage1, Image2: ieImage2 }

const images = {
	[countryCodes.us]: usImages,
	[countryCodes.nz]: anzImages,
	[countryCodes.au]: anzImages,
	[countryCodes.uk]: ukImages,
	[countryCodes.ie]: ieImages,
}

const constants = country => images[country]
export default constants
