import * as React from 'react'
import styled from 'styled-components'

import LargeImage from './large-image'
import Thumbnails from './thumbnails'
import { ContentWrapper } from 'views/components'
import { mobile } from 'shared/media-queries'

const LargeImageWrapper = styled.div`
	display: flex;
	justify-content: center;
	background-color: #81827d;
`
const ThumbnailsWrapper = styled.div`
	padding-top: 40px;
	display: flex;
	justify-content: space-around;
	${mobile} {
		padding-top: 10px;
	}
`

const Videos = ({ largeImage, country }) => (
	<div>
		<LargeImageWrapper>
			<LargeImage quote={largeImage.quote} />
		</LargeImageWrapper>
		<ContentWrapper>
			<ThumbnailsWrapper>
				<Thumbnails country={country} />
			</ThumbnailsWrapper>
		</ContentWrapper>
	</div>
)

export default Videos
