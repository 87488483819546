import * as React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import { grayText } from 'shared/colors'
import { mobile } from 'shared/media-queries'

const Title = styled.div`
	font-size: 32px;
	font-weight: 500;
	color: ${grayText};
	${mobile} {
		font-size: 26px;
	}
`
const InfoWrapper = styled.div`
	padding: 20px 0;
	display: flex;
	${mobile} {
		max-width: 350px;
		margin: 0 auto;
	}
`
const Image = styled.div`
	flex: 0 0 100px;
`

const Info = ({ image, title, text }) => (
	<InfoWrapper>
		<Image>{image}</Image>
		<div>
			<Title>{title}</Title>
			<div>{text}</div>
		</div>
	</InfoWrapper>
)

// prettier-ignore
interface Content {
		image1: {
			title: string;
			text: string;
		};
		image2: {
			title: string;
			text: string;
		};
		image3: {
			title: string;
			text: string;
		};
}

const InfoSection: React.FC<Content> = imageCopy => {
	const data = useStaticQuery(
		graphql`
			query {
				image1: file(
					relativePath: { eq: "homepage/network/info-section/image1.jpg" }
				) {
					childImageSharp {
						fixed(height: 74, width: 74) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				image2: file(
					relativePath: { eq: "homepage/network/info-section/image2.jpg" }
				) {
					childImageSharp {
						fixed(height: 74, width: 74) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
				image3: file(
					relativePath: { eq: "homepage/network/info-section/image3.jpg" }
				) {
					childImageSharp {
						fixed(height: 74, width: 74) {
							...GatsbyImageSharpFixed_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<>
			<Info
				image={<GatsbyImage fixed={data.image1.childImageSharp.fixed} />}
				title={imageCopy.image1.title}
				text={imageCopy.image1.text}
			/>
			<Info
				image={<GatsbyImage fixed={data.image2.childImageSharp.fixed} />}
				title={imageCopy.image2.title}
				text={imageCopy.image2.text}
			/>
			<Info
				image={<GatsbyImage fixed={data.image3.childImageSharp.fixed} />}
				title={imageCopy.image3.title}
				text={imageCopy.image3.text}
			/>
		</>
	)
}

export default InfoSection
