import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image/withIEPolyfill'

import { breakpoints } from 'shared/media-queries'

const Image = () => {
	const data = useStaticQuery(
		graphql`
			query {
				desktop: file(relativePath: { eq: "homepage/network/desktop.jpg" }) {
					childImageSharp {
						fluid(maxWidth: 1000) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mobile: file(relativePath: { eq: "homepage/network/mobile.jpg" }) {
					childImageSharp {
						fluid(maxHeight: 500) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	const sources = [
		{
			...data.desktop.childImageSharp.fluid,
			media: `(min-width: ${breakpoints.laptop}px)`,
		},
		data.mobile.childImageSharp.fluid,
	]

	return (
		<GatsbyImage
			fluid={sources}
			alt="homepage network image"
			style={{ width: '100%', height: '100%' }}
		/>
	)
}

export default Image
