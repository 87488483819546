import * as React from 'react'
import styled from 'styled-components'

import { Hero, H1 } from 'views/components'
import { lightBlue } from 'shared/colors'
import { tablet, mobile } from 'shared/media-queries'
import backgroundVideoMp4 from './background.mp4'

const Heading = styled(H1)`
	font-size: 54px;
	max-width: 625px;
	${tablet} {
		margin: 0 auto;
	}
	${mobile} {
		font-size: 36px;
	}
`
const SubHeading = styled.div`
	max-width: 550px;
	font-size: 24px;
	${tablet} {
		margin: 0 auto;
	}
	${mobile} {
		font-size: 18px;
	}
`

// prettier-ignore
interface Content {
	heading: string;
	subheading: string;
}

const Header: React.FC<Content> = ({ heading, subheading }) => (
	<Hero
		backgroundVideo={{
			mp4: backgroundVideoMp4,
		}}
		backgroundColor="#042149"
		leftAlign
		wordingWidth="100%"
		heading={<Heading>{heading}</Heading>}
		subHeading={<SubHeading>{subheading}</SubHeading>}
		ctaColor={lightBlue}
	/>
)

export default Header
