import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import styled from 'styled-components'
import { isUSA } from 'shared/get-country'
import { WistiaVideoOverlay } from 'views/components'
import PlayButtonSVG from 'icons/play-button.svg'
import { laptop, tablet, mobile } from 'shared/media-queries'

const ImageWrapper = styled.div`
	position: relative;
	flex: 0 0 385px;
	min-width: 0;
	height: 100%;
	${laptop} {
		flex: 0 0 300px;
	}
	${tablet} {
		flex: 0 0 220px;
	}
	${mobile} {
		flex: 1 1 0%;
	}
`
const PlayButtonWrapper = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 15px;
	display: flex;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	${tablet} {
		padding: 10px;
		svg {
			height: 30px;
			width: 30px;
		}
	}
	${mobile} {
		padding: 5px;
		svg {
			height: 25px;
			width: 25px;
		}
	}
`

const VideoThumbnails = ({ country }) => {
	const data = useStaticQuery(
		graphql`
			query {
				image1: file(
					relativePath: { eq: "homepage/videos/thumbnails/image1.jpg" }
				) {
					childImageSharp {
						fluid(maxHeight: 210) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image2: file(
					relativePath: { eq: "homepage/videos/thumbnails/image2.jpg" }
				) {
					childImageSharp {
						fluid(maxHeight: 210) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image2Other: file(
					relativePath: { eq: "homepage/videos/thumbnails/image2Other.png" }
				) {
					childImageSharp {
						fluid(maxHeight: 210) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image3: file(
					relativePath: { eq: "homepage/videos/thumbnails/image3.jpg" }
				) {
					childImageSharp {
						fluid(maxHeight: 210) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<>
			<ImageWrapper>
				<GatsbyImage
					fluid={data.image1.childImageSharp.fluid}
					style={{ display: 'block', maxWidth: '100%' }}
				/>
				<PlayButtonWrapper>
					<WistiaVideoOverlay videoId="o71tq35sde" />
					<PlayButtonSVG />
				</PlayButtonWrapper>
			</ImageWrapper>
			<ImageWrapper>
				<GatsbyImage
					fluid={
						isUSA(country)
							? data.image2.childImageSharp.fluid
							: data.image2Other.childImageSharp.fluid
					}
					style={{ display: 'block', maxWidth: '100%' }}
				/>
				<PlayButtonWrapper>
					<WistiaVideoOverlay
						videoId={isUSA(country) ? 'gwil2wxtmp' : '73ldtdtn25'}
					/>
					<PlayButtonSVG />
				</PlayButtonWrapper>
			</ImageWrapper>
			<ImageWrapper>
				<GatsbyImage
					fluid={data.image3.childImageSharp.fluid}
					style={{ display: 'block', maxWidth: '100%' }}
				/>
				<PlayButtonWrapper>
					<WistiaVideoOverlay videoId="fv3uejg15c" />
					<PlayButtonSVG />
				</PlayButtonWrapper>
			</ImageWrapper>
		</>
	)
}

export default VideoThumbnails
