import * as React from 'react'
import styled from 'styled-components'

import {
	ContentWrapper,
	SectionBreak,
	SectionSpacer,
	Footer,
} from 'views/components'
import { lightBlue } from 'shared/colors'
import Layout from 'components/layout'
import Header from './header'
import GCLogos from 'components/gc-logos'
import Network from './network'
import Solutions from './solutions'
import Videos from './videos'
import generateContent from './content'

import withCountry from 'shared/get-country'

const GCLogosWrapper = styled.div`
	padding: 60px 0;
`
// prettier-ignore
interface ContentType {
	header: any;
	network: any;
	solutions: any;
	videos: any;
}

const Homepage = ({ country }) => {
	const content: ContentType = generateContent(country)
	if (!country) return null
	const { header, network, solutions, videos } = content

	return (
		<Layout
			title="BuildingConnected | Preconstruction Software"
			description="Thousands of contractors use our preconstruction software to send invitations to bid, qualify vendors, and track opportunities with ease."
		>
			<Header {...header} />
			<ContentWrapper>
				<GCLogosWrapper>
					<GCLogos />
				</GCLogosWrapper>
				<Network {...network} />
				<SectionBreak />
				<Solutions {...solutions} country={country} />
				<SectionSpacer />
			</ContentWrapper>
			<Videos {...videos} />
			<SectionSpacer />
			<Footer
				wording="Join BuildingConnected for free today—and get building."
				ctaColor={lightBlue}
				demoButtonPadding="60px"
				demoButtonWording="Get a free trial"
				showCreateAccount
				wordingMaxWidth="750px"
			/>
		</Layout>
	)
}

export default withCountry(Homepage)
