import * as React from 'react'
import styled from 'styled-components'
import { InfoAndImage } from 'views/components'
import InfoSection from './info-section'
import _images from './images'
import { laptop, tablet } from 'shared/media-queries'

const ImageWrapper = styled.div`
	width: 1000px;
	${tablet} {
		width: 100%;
	}
`
const WidthWrapper = styled.div`
	width: 550px;
	${laptop} {
		width: 450px;
	}
	${tablet} {
		width: 100%;
	}
`
const TitleWrapper = styled(WidthWrapper)`
	${tablet} {
		text-align: center;
		max-width: 400px;
		margin: 0 auto;
	}
`
// prettier-ignore
interface Content {
	title: string;
	subtitle: string;
	info: any;
	country: string;
}

const Network: React.FC<Content> = ({ title, subtitle, info, country }) => {
	const [openIndex, setOpenIndex] = React.useState(0)
	// @ts-ignore
	const { Image1, Image2, Image3 } = _images(country)

	let image = <Image1 />
	if (openIndex === 1) {
		image = <Image2 />
	} else if (openIndex === 2 && !!Image3) {
		image = <Image3 />
	}

	return (
		<InfoAndImage
			imageOnRight
			image={<ImageWrapper>{image}</ImageWrapper>}
			title={<TitleWrapper>{title}</TitleWrapper>}
			subtitle={<TitleWrapper>{subtitle}</TitleWrapper>}
			info={
				<WidthWrapper>
					<InfoSection
						openIndex={openIndex}
						setOpenIndex={setOpenIndex}
						content={info}
					/>
				</WidthWrapper>
			}
			wordingWidth="auto"
		/>
	)
}

export default Network
