import * as React from 'react'
import { contractorTypeByLocale, isUSA } from 'shared/get-country'

const usCopy = {
	header: {
		heading: 'The network that powers preconstruction.',
		subheading:
			'The largest real-time construction network that connects owners and builders through an easy-to-use platform to streamline the bid and risk management process.',
	},
	network: {
		title: 'THE BUILDINGCONNECTED NETWORK',
		subtitle: 'Connect with over 1 million construction professionals.',
		images: {
			image1: {
				title: 'General Contractors',
				text: 'Find and qualify subcontractors.',
			},
			image2: { title: 'Subcontractors', text: 'Get discovered and win bids.' },
			image3: {
				title: 'Owners',
				text: 'Access the most extensive network of builders.',
			},
		},
	},
	solutions: {
		title: 'NETWORK SOLUTIONS',
		subtitle: '3 tools that simplify preconstruction.',
		info: [
			{
				title: (
					<>
						<b>BuildingConnected Pro</b> for general contractors and owners
					</>
				),
				text: 'Centralize bid management and increase ROI.',
				dropdown: `Use the most up-to-date construction network to invite the right subcontractors, manage proposals and award the best bids.`,
				learnMoreUrl: '/bc-pro',
			},
			{
				title: (
					<>
						<b>TradeTapp</b> for risk teams
					</>
				),
				text: 'Mitigate risk right from the start.',
				dropdown: `Qualify subcontractors through automated analysis and recommendations to mitigate risks sooner.`,
				learnMoreUrl: '/tradetapp',
			},
			{
				title: (
					<>
						<b>Bid Board Pro</b> for subcontractors
					</>
				),
				text: 'Centralize your bids and collaborate in real time.',
				dropdown: `Track bid invites, projects, who’s working on what and due dates across the entire office—all from one place.`,
				learnMoreUrl: '/bid-board',
			},
		],
	},
	videos: {
		largeImage: {
			quote:
				'As soon as I open bids, I can see instantly whether or not they’re approved to work with Barton Malow.',
		},
	},
}

const otherCopy = country => ({
	header: {
		heading: "Autodesk's #1 tender management solution",
		subheading: `Connecting Owners, ${contractorTypeByLocale(
			country
		)} and Subcontractors through a real-time network to make tendering easy.`,
	},
	network: {
		title: 'THE BUILDINGCONNECTED NETWORK',
		subtitle: 'Connect with construction professionals.',
		images: {
			image1: {
				title: `${contractorTypeByLocale(country)}`,
				text: 'Find and invite subcontractors.',
			},
			image2: {
				title: 'Subcontractors',
				text: 'Get discovered and send quotes.',
			},
			image3: { title: 'Owners', text: 'Find builders and manage jobs.' },
		},
	},
	solutions: {
		title: 'NETWORK SOLUTIONS',
		subtitle: 'Tools that simplify preconstruction.',
		info: [
			{
				title: (
					<>
						<b>BuildingConnected Pro</b>{' '}
						{`for ${contractorTypeByLocale(country)} and Owners`}
					</>
				),
				text: 'Centralise tender management and increase ROI.',
				dropdown: `Use the most up-to-date construction network to invite the right subcontractors, manage proposals and award the best tenders.`,
				learnMoreUrl: '/bc-pro',
			},
			{
				title: (
					<>
						<b>Tendering</b> for subcontractors
					</>
				),
				text: 'Get invited to tender and send quotes.',
				dropdown: `See all your tenders, respond to jobs and send quotes.`,
				learnMoreUrl: '/bid-board/bid-invites',
			},
		],
	},
	videos: {
		largeImage: {
			quote:
				'As soon as I open tenders, I can see instantly whether or not they’re approved to work with Barton Malow.',
		},
	},
})

const content = country => (isUSA(country) ? usCopy : otherCopy(country))

export default content
