import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { grayText, brandBlue } from 'shared/colors'
import Caret from 'icons/caret.svg'
import { tablet } from 'shared/media-queries'

const InfoWrapper = styled.div`
	padding: 20px 0;
	color: ${grayText};
	display: flex;
	${tablet} {
		padding: 5px 0;
	}
`
const CaretWrapper = styled.div`
	margin: 5px 0 0 20px;
	flex: 0 0 70px;
`
const StyledCaret = styled(Caret)`
	${props => props.open && 'transform: rotate(180deg);'}
	transition: transform 200ms;
`
const Title = styled.div`
	font-size: 30px;
	line-height: 30px;
	font-weight: 500;
	padding-bottom: 3px;
	${tablet} {
		font-size: 24px;
	}
`
const Text = styled.div`
	font-size: 22px;
	${tablet} {
		font-size: 18px;
	}
`
const Dropdown = styled(Text)`
	padding-top: 30px;
`
const LinkWrapper = styled.div`
	padding-top: 20px;
	font-weight: 500;
	cursor: pointer;

	a {
		color: ${brandBlue};
	}
`

// prettier-ignore
interface InfoInterface {
  title: React.ReactNode;
  text: React.ReactNode;
  dropdown: React.ReactNode;
  learnMoreUrl: string;
  open: boolean;
  onClick: () => any;
}
const Info: React.FC<InfoInterface> = ({
	title,
	text,
	dropdown,
	learnMoreUrl,
	open,
	onClick,
}) => (
	<InfoWrapper onClick={onClick}>
		<CaretWrapper>
			<StyledCaret open={open} />
		</CaretWrapper>
		<div>
			<Title>{title}</Title>
			<Text>{text}</Text>
			{open && (
				<Dropdown>
					<div>{dropdown}</div>
					<LinkWrapper>
						<Link to={learnMoreUrl}>Learn more</Link>
					</LinkWrapper>
				</Dropdown>
			)}
		</div>
	</InfoWrapper>
)

// prettier-ignore
interface ContentProps {
	title: any;
	text: string;
	dropdown: string;
	learnMoreUrl: string;
}
// prettier-ignore
interface InfoSectionInterface {
	openIndex: number;
	setOpenIndex: (number) => void;
	content: Array<ContentProps>
}
const InfoSection: React.FC<InfoSectionInterface> = ({
	openIndex,
	setOpenIndex,
	content,
}) => (
	<>
		{content.map((infoContent, i) => (
			<React.Fragment key={i}>
				<Info
					{...infoContent}
					open={openIndex === i}
					onClick={() => setOpenIndex(i)}
				/>
				{i < content.length - 1 && <hr />}
			</React.Fragment>
		))}
	</>
)

export default InfoSection
