import * as React from 'react'
import styled from 'styled-components'

import { InfoAndImage } from 'views/components'
import Image from './image'
import InfoSection from './info-section'
import { laptop, tablet, mobile } from 'shared/media-queries'

const WidthContainer = styled.div`
	width: 500px;
	${laptop} {
		width: 450px;
	}
	${tablet} {
		width: 475px;
		margin: 0 auto;
	}
	${mobile} {
		width: 100%;
	}
`
const HeaderContainer = styled(WidthContainer)`
	${tablet} {
		text-align: center;
	}
`
const ImageWrapper = styled.div`
	${laptop} {
		max-width: 600px;
		margin: 0 auto;
	}
	${tablet} {
		max-width: 100%;
		height: 250px;

		img {
			object-fit: contain !important;
		}
	}
	${mobile} {
		height: 200px;
	}
`

// prettier-ignore
interface Content {
	title: string;
	subtitle: string;
	images: any;
}

const Network: React.FC<Content> = ({ title, subtitle, images }) => (
	<InfoAndImage
		imageOnRight
		image={
			<ImageWrapper>
				<Image />
			</ImageWrapper>
		}
		title={<HeaderContainer>{title}</HeaderContainer>}
		subtitle={<HeaderContainer>{subtitle}</HeaderContainer>}
		info={
			<WidthContainer>
				<InfoSection {...images} />
			</WidthContainer>
		}
		spacerWidths={['100px', '50px']}
		wordingWidth="auto"
	/>
)

export default Network
